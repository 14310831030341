import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const Image = ({ src,alt, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
            edges {
                node {
                    relativePath
                    extension
                    publicURL
                    childImageSharp {                      
                        gatsbyImageData(                          
                          layout: CONSTRAINED                        
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]                                                          
                        )
                    }
                }
            }
        }    
    }
  `)

  const match = useMemo(
    () => data.images.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  );

  if (!match) return null;

  const { node: { childImageSharp, publicURL, extension } = {} } = match;

  if (extension === "svg" || !childImageSharp) {
    return <img src={publicURL} alt={alt} {...rest} />;
  }

 
    return    <GatsbyImage image={childImageSharp.gatsbyImageData} alt={alt}  {...rest} />
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default Image;